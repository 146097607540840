h1 {
  margin: 40px;
  text-align: left;
}

p {
  text-align: left;
  line-height: 1.5;
}

.App {
  text-align: center;
  font-family: "Lexend", sans-serif;
}

.section {
  margin: 20px;
  border-radius: 16px;
  color: white;
  flex-direction: row;
  display: flex;
  justify-content: left;
  text-align: left;
  transition: all 1s ease;
}

.section-box {
  padding: 0px;
  margin: 20px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.05);
  color: white;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  flex-direction: column;
  display: flex;
  justify-content: left;
  transition: all 0.3s ease;
  cursor: pointer;
  text-align: left;
}

/*Sectionbox hover effect*/
.section-box:hover {
  transform: scale(1.05);
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease;
}


.feed-button {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
  transition: all 0.5s ease;
  font-family: "Lexend", sans-serif;
}

.feed-button:hover {
  background: rgba(255, 255, 255, 0.5);
  color: black;
  transition: all 0.5s ease;
}

.contact-button{
  background-color: rgb(55, 55, 55);
  color: white;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  height: fit-content;
  border: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
  transition: all 1s ease;
  font-family: "Lexend", sans-serif;
}

.contact-button:hover {
  background-color: rgba(164, 164, 164, 0.5);
  color: black;
  transition: all 1s ease;
}

.contact-button:focus-within {
  background-color: rgba(164, 164, 164, 0.5);
  color: black;
  transition: all 1s ease;
}