.deleteAccount.container {
    padding: 20px;
    margin: 50px auto;
    max-width: 800px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.deleteAccount p{
    text-align: left;
    margin: 50px 50px;
}

.deleteAccount ol {
    list-style-type: none;
    text-align: left;
}

.deleteAccount img {
    width: 30%;
    margin: 50px auto;
    display: block;
}